<template>
  <div>
    <div class="d-flex justify-content-between align-content-center border-bottom">
      <h3 class="text-muted font-weight-bold mb-2">اضافة الأوراق المطلوبة</h3>
    </div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form class="position-relative form-top " @submit.prevent="handleSubmit(addRequirement)">
          <b-tabs content-class="mt-3 w-50">
            <b-tab v-for="(lang, key) in translations" :key="key" :title="lang.code" >
              <input-form :label="' الاسم -' + lang.code " placeholder="الاسم" v-model="lang.name" :validate="lang.is_default ? 'required':'' " />
              <input name="translations[lang]" :value="lang.code" type="hidden"/>
            </b-tab>
          </b-tabs>
          <div class="d-flex align-items-center justify-content-center">
            <b-button variant="primary" :disabled="invalid" type="submit"><span  class="font-size-18 px-5">اضافة</span></b-button>
          </div>
        </b-form>
      </ValidationObserver>
  </div>
</template>

<script>
import getLanguages from '@/mixins/getLanguages'
import transportationServices from '@/modules/transportation/services/transportation'
import { core } from '@/config/pluginInit'
// import transportationServices from '@/modules/transportation/services/transportation'
export default {
  name: 'requirementsModal',
  mixins: [getLanguages],
  data () {
    return {
      translatedColumns: ['name', 'title'],
      translations: []
    }
  },
  // addType () {
  //   if (this.$route.name === 'editVehicleType') {
  //     this.editType()
  //   } else {
  //     const finalTranslations = this.translations.filter(translation => this.translatedColumns.some(column => translation[column]))
  //     transportationServices.addVehicleType({
  //       ...this.vehicleType,
  //       translations: finalTranslations
  //     }).then(res => {
  //       core.showSnackbar('success', res.data.message)
  //       this.$router.push({ name: 'vehiclesTypes' })
  //     })
  //   }
  // }
  methods: {
    addRequirement () {
      const finalTranslations = this.translations.filter(translation => this.translatedColumns.some(column => translation[column]))
      transportationServices.addRequiredDocument({
        ...this.vehicleType,
        translations: finalTranslations
      }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ name: 'vehiclesTypes' })
      })
    }
  },
  created () {
    this.getAllLanguages.forEach(data => {
      const translation = {
        lang: data.code,
        ...data
      }
      this.translatedColumns.forEach(data => {
        translation[data] = null
      })
      this.translations.push(translation)
    })
  }
}
</script>

<style scoped>

</style>
